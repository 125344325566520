<template>
    <div class="addressComp">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="地址信息：" prop="addressInfo" required>
                <!-- <div class="region">
                    <el-select v-model="ruleForm.province" placeholder="省">
                        <el-option
                        v-for="item in provinceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="ruleForm.city" placeholder="市">
                        <el-option
                        v-for="item in cityList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="ruleForm.county" placeholder="区县">
                        <el-option
                        v-for="item in countyList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->

                <!-- <el-cascader
                v-model="ruleForm.addressInfo"
                :props="areaProps"
                @change="handleChange"></el-cascader> -->
                <el-cascader
                v-model="ruleForm.addressInfo"
                :options="areaOptions"
                @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址：" prop="address" required>
                <el-input type="textarea" v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名：" prop="receiveUser" required>
                <el-input v-model="ruleForm.receiveUser"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="telephone" required>
                <div class="phoneLine">
                    <el-select v-model="ruleForm.location" placeholder="">
                        <el-option
                        v-for="item in locationList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input v-model="ruleForm.telephone" placeholder="请填写你的联系方式"></el-input>
                </div>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="ruleForm.isDefault"></el-checkbox>&nbsp;设为默认地址
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveAddress">&emsp;保存&emsp;</el-button>
                <el-button @click="$emit('close')">&emsp;取消&emsp;</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {cityData} from "@/assets/js/areaData.js"
import {getCityListAll,addReceiveAddress,editReceiveAddress,editDefaultReceiveAddress,
getReceiveAddressList,deleteReceiveAddress} from "@/axios/api"
export default {
    props:['editAddressId'],
    data(){
        return{
            ruleForm:{
                receiveUser:"",
                addressInfo:[],
                address:"",
                telephone:"",
                location:1,
                receiveAddressId:"",
                isDefault:false,
            },
            rules:{
                addressInfo:[
                    { required: true, message: '请选择地区', trigger: 'blur' },
                ],
                address:[
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
                receiveUser:[
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                telephone:[
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                ],
            },
            areaProps: {
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level } = node;
                    // console.log(node)
                    getCityListAll({
                        level:level+1,
                        pid:node.data?node.data.id:""
                    }).then(res => {
                        // console.log("地区数据",res)
                        res.data.forEach(item=>{
                            item.label=item.name;
                            item.value=item.id;
                            if(level>=2){
                                item.leaf=true
                            }
                        })
                        // console.log(res.data)
                        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                        resolve(res.data);
                    }, 1000);
                }
            },
            areaOptions:[],
            areaOptions:cityData,
            locationList:[
                {
                    label:"中国大陆+86",
                    value:1,
                }
            ],
        }
    },
    methods: {
        handleChange(){},
        gainCityListAll(id,level,arr){
            getCityListAll({
                level,
                pid:id?id:""
            }).then(res=>{
                // console.log("地区数据",res);
                if(res.code==200){
                    res.data.forEach(item=>{
                        var obj={
                            label:item.name,
                            value:item.id,
                        }
                        if(level<3){
                            obj.children=[]
                        }
                        arr.push(obj)
                    })
                    // console.log(arr)
                    // console.log(this.areaOptions)
                    // if(level==1){
                    //     this.areaOptions=arr
                    // }
                    if(level<3){
                        arr.forEach(item=>{
                            this.gainCityListAll(item.value,level+1,item.children)
                        })
                    }
                }
            })
        },
        saveAddress(){
            this.$refs.ruleForm.validate((valid)=>{
                if(valid){
                    var obj={
                        receiveUser:this.ruleForm.receiveUser,
                        province:this.ruleForm.addressInfo[0],
                        city:this.ruleForm.addressInfo[1],
                        county:this.ruleForm.addressInfo[2],
                        address:this.ruleForm.address,
                        telephone:this.ruleForm.telephone,
                        isDefault:this.ruleForm.isDefault?"Y":"N",
                        receiveAddressId:this.ruleForm.receiveAddressId,
                    }
                    if(!this.ruleForm.receiveAddressId){
                        console.log("id不存在即为新增")
                        addReceiveAddress(obj).then(res=>{
                            console.log("新增收货地址结果",res);
                            if(res.code==200){
                                this.$message.success(res.msg||res.message);
                                this.$emit("close")
                                // this.gainAddressList();

                                // setTimeout(()=>{
                                //     this.ruleForm={
                                //         receiveUser:"",
                                //         addressInfo:[],
                                //         address:"",
                                //         telephone:"",
                                //         location:1,
                                //         receiveAddressId:"",
                                //         isDefault:false
                                //     }
                                // },500)
                                // this.ruleForm.address="";
                            }else{
                                this.$message(res.msg||res.message)
                            }
                        })
                    }else{
                        editReceiveAddress(obj).then(res=>{
                            console.log("编辑收货地址结果",res);
                            if(res.code==200){
                                this.$message.success(res.msg||res.message);
                                this.$emit("close")
                                // this.gainAddressList();

                                // setTimeout(()=>{
                                //     this.ruleForm={
                                //         receiveUser:"",
                                //         addressInfo:[],
                                //         address:"",
                                //         telephone:"",
                                //         location:1,
                                //         receiveAddressId:"",
                                //         isDefault:false
                                //     }
                                // },500)
                            }else{
                                this.$message(res.msg||res.message)
                            }
                        })
                    }
                }
            })
            // console.log(this.ruleForm.addressInfo)
        },
        // 获取收货地址列表
        gainAddressList(){
            getReceiveAddressList().then(res=>{
                console.log("收货地址列表",res);
                if(res.code==200){
                    res.data.forEach(item=>{
                        item.belongArea=item.provinceName+" "+item.cityName+" "+item.countyName
                    })
                    this.tableData=res.data;
                    
                    for(var i=0;i<this.tableData.length;i++){
                        if(this.tableData[i].receiveAddressId==this.editAddressId){
                            this.clickEdit(this.tableData[i])
                            break
                        }
                    }
                }
            })
        },
        // 点击修改
        clickEdit(row){
            console.log(row);
            this.ruleForm.receiveUser=row.receiveUser;
            this.ruleForm.telephone=row.telephone;
            this.ruleForm.address=row.address;
            this.ruleForm.receiveAddressId=row.receiveAddressId;
            this.ruleForm.addressInfo=[row.province,row.city,row.county];
            this.ruleForm.isDefault=row.isDefault=="Y"?true:false;
        },
    },
    created() {
        // this.gainCityListAll(0,1,this.areaOptions);
        // console.log(this.areaOptions);

        // cityData[0].children[1].forEach(item=>{
        //     cityData[0].children[0].push(item)
        // })
        // cityData[0].children[0].label="市辖区"

        cityData.forEach(item=>{
            var index=['北京市','天津市','重庆市','上海市'].indexOf(item.label)
            if(index!=-1){
                item.children[0].children=item.children[0].children.concat(item.children[1].children);
                delete item.children[1];
                // console.log(item.children[0])
                item.children[0].label=['北京市','天津市','重庆市'][index]
            }
        })
        // console.log(cityData)
        // this.areaOptions=cityData;
        
        if(this.editAddressId!=""){
            this.gainAddressList();
        }
    },
    watch:{
        'editAddressId'(n,o){
            if(n!=""){
                this.gainAddressList();
            }else{
                this.ruleForm={
                    receiveUser:"",
                    addressInfo:[],
                    address:"",
                    telephone:"",
                    location:1,
                    receiveAddressId:"",
                    isDefault:false
                }

                this.$refs.ruleForm.resetFields();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.addressComp{
    .el-form{
        margin-top: 20px;
        /deep/.el-form-item{
            .el-input{
                width: 396px;
            }
            .el-textarea{
                width: 396px;
                .el-textarea__inner{
                    resize: none;
                }
            }
            .phoneLine{
                .el-select{
                    .el-input{
                        width: 178px;
                    }
                }
                &>.el-input{
                    width: 206px;
                    margin-left: 12px;
                }
            }
        }
    }
}
</style>