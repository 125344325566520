<template>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="fit-content"
        :before-close="handleClose"
        @close="diaClose"
        :close-on-click-modal="false"
        :modal-append-to-body="true"
        :show-close="false">

        <div class="tipBox" v-if="true">
            <div class="ti">
                <i class="el-icon-success"></i>
                订单成功提示
            </div>
            <div class="sn">订单号：{{order.orderSn}}</div>
            <div class="price">应付金额：
                ￥<span>{{order.amount}}</span>
            </div>
            <div class="ope">
                <div class="imm" @click="immPay">立即付款</div>
                <div class="out" @click="$emit('close')">稍后付款</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props:['order','dialogVisible'],
    data(){
        return{
            // dialogVisible:false,
        }
    },
    methods: {
        handleClose(done){
            done();
        },
        diaClose(){},
        immPay(){
            this.$router.push({
                path:"/payPage",
                query:{s:this.order.orderId}
            })
            // this.dialogVisible=false;
            this.$emit('close')
        },
    },
}
</script>

<style lang="less" scoped>
.tipBox{
    // width: 100%;
    // height: 100%;
    width: 577px;
    height: 334px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ti{
        width: 300px;
        font-size: 20px;
        font-weight: 400;
        color: #333;
        display: flex;
        align-items: center;
        i{
            color: rgba(0, 128, 0,0.6);
            font-size: 32px;
            margin-right: 10px;
        }
    }
    .sn{
        margin: 24px 0;
        width: 300px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
    }
    .price{
        width: 300px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        &>span{
            color: rgba(255, 87, 51, 1)
        }
    }
    .ope{
        margin-top: 34px;
        width: 300px;
        display: flex;
        align-items: center;
        .imm{
            width: 112px;
            height: 34px;
            opacity: 1;
            border-radius: 2px;
            background: rgba(255, 87, 51, 1);
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .out{
            width: 112px;
            height: 34px;
            opacity: 1;
            border-radius: 2px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(166, 166, 166, 1);
            margin-left: 27px;

            font-size: 16px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}
</style>