<template>
    <div class="payCompContainer">
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="100%"
            :before-close="handleClose"
            @close="diaClose"
            :close-on-click-modal="false"
            :modal-append-to-body="true">
            
            <div class="payComp" v-if="true">
                <div class="close">
                    <i class="el-icon-close" @click="close"></i>
                </div>
                <!-- <div class="receiveInfo">
                    <div>
                        <span>李四</span>
                        <span>18888</span>
                    </div>
                    <div>北京市海淀区</div>
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div class="gapLine">
                    <img src="../assets/imgs/effect21.png"/>
                </div> -->
                <div class="goodsShow">
                    <div class="item" v-for="(item,i) in clearGoodsList" :key="i">
                        <div class="goods">
                            <img :src="item.cover"/>
                            <div class="info">
                                <div class="name">{{item.name}}</div>
                                <div class="specification">
                                    <span>颜色:{{item.color}}</span>
                                    <span>尺寸:{{item.productDimension}}</span>
                                </div>
                                <div class="priceNum">
                                    <div class="price">￥{{item.ourShopPrice}}</div>
                                    <div>x{{item.number||item._goodsNumber}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="msg">
                            <span>买家留言:</span>
                            <span>
                                <input placeholder="想说啥,就说啥,客气啥啊"/>
                            </span>
                        </div>
                        <div class="freight">
                            <span>运费</span>
                            <span>免运费</span>
                        </div>
                        <div class="goodsPrice">
                            <span>商品金额</span>
                            <span>￥{{item.ourShopPrice}}</span>
                        </div>
                    </div>
                </div>
                <div class="gapLine">
                    <img src="../assets/imgs/effect21.png"/>
                </div>
                <div class="payInfo">
                    <div class="payPrice">
                        <span>支付金额</span>
                        <span>￥{{payForPrice}}</span>
                    </div>
                    <div class="payAccount" v-for="(item,i) in receiveAccountList" :key="i" v-show="i==0">
                        <span>{{['','','支付宝','微信'][item.type]}}</span>
                        <div class="qr" :class="['qr_'+i]">
                            <!-- <img/> -->
                        </div>
                    </div>
                </div>
                <div class="tip">
                    <!-- 支付成功后；请在订单详情页面上传支付凭证 -->
                    支付成功后；请在订单页面上传支付凭证
                </div>
            </div>

            <div class="tipBox" v-if="false">
                <div class="ti">
                    <i class="el-icon-success"></i>
                    订单成功提示
                </div>
                <div class="sn">订单号：{{order.orderSn}}</div>
                <div class="price">应付金额：
                    ￥<span>{{order.amount}}</span>
                </div>
                <div class="ope">
                    <div class="imm" @click="immPay">立即付款</div>
                    <div class="out" @click="dialogVisible=false;$emit('close')">稍后付款</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getReceiveAccountList} from "@/axios/api"
export default {
    props:['clearGoodsList','showPayComp','order'],
    data(){
        return{
            dialogVisible:false,
            receiveAccountList:[],
            payForPrice:0,
        }
    },
    methods: {
        handleClose(){
            console.log("beforeClose")
        },
        gainAccountList(){
            getReceiveAccountList().then(res=>{
                console.log("收款账户列表",res);
                if(res.code==200){
                    this.receiveAccountList=res.data;
                    this.$nextTick(()=>{
                        this.createQR(res.data[0].qrcode)
                    })
                }
            })
        },
        // 生成二维码
        createQR(url){
            // this.receiveAccountList.forEach((item,i)=>{
            //     new QRCode(document.getElementsByClassName('qr_'+i)[0],{
            //         text:url,
            //         width:76,
            //         height:76,
            //         colorDark:"#000000",
            //         colorLight:"#ffffff"
            //     })
            // })
            var qrcode=new QRCode(document.getElementsByClassName('qr')[0],{
                text:url,
                width:76,
                height:76,
                colorDark:"#000000",
                colorLight:"#ffffff"
            })
        },
        calcPrice(){
            var price=0;
            this.clearGoodsList.forEach(item=>{
                price+=parseFloat(item.ourShopPrice)*(item.number?item.number:item._goodsNumber)
                console.log(item)
            })
            this.payForPrice=price
        },
        // 关闭
        close(){
            this.dialogVisible=false;
            this.$emit('close')
        },
        diaClose(){
            console.log(123456)
        },

        // 跳转支付
        immPay(){
            console.log(this.orderId)
            this.$router.push({
                path:"/payPage",
                query:{s:this.order.orderId}
            })
            this.dialogVisible=false;
            this.$emit('close')
        }
    },
    mounted() {
        // this.gainAccountList();
    },
    watch:{
        'clearGoodsList'(n,o){
            this.calcPrice()
        },
        'showPayComp'(n,o){
            if(n){
                this.dialogVisible=true;
                this.$nextTick(()=>{
                    if(this.receiveAccountList.length==0){
                        this.gainAccountList();
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.payCompContainer{
    // width: 390px;

    width:420px;
    height:80vh;

    // width: 577px;
    // height: 334px;
    /deep/.el-dialog{
        height:100%;
        margin:0!important;
        .el-dialog__header{
            display:none;
        }
        .el-dialog__body{
            padding:0;
            height:100%;
            width:100%;
            padding:0 10px;            
        }
    }
    .payComp{
        // width: 390px;
        height:100%;
        .close{
            display: flex;
            justify-content: flex-end;
            padding: 15px 0 0 15px;
            &>i{
                font-size: 20px;
                cursor: pointer;
            }
        }
        .receiveInfo{
            position: relative;
            padding: 20px 15px;
            &>div:nth-child(1){
                display: flex;
                font-size: 16px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                &>span:nth-child(2){
                    margin-left: 25px;
                    font-size: 15px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 1);
                }
            }
            &>div:nth-child(2){
                margin-top: 10px;
                font-size: 13px;
                font-weight: 400;
                color: rgba(128, 128, 128, 1);
            }
            &>i{
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                cursor: pointer;
            }
        }
        .gapLine{
            img{
                width: 100%;
            }
        }
        .goodsShow{
            // max-height: 200px;
            // max-height: calc(100vh - 580px);
            max-height: calc(100vh - 480px);
            overflow: auto;
            &::-webkit-scrollbar{
                width: 4px;
                background: #ccc
            }
            .item{
                padding: 15px;
                border-bottom: 1px solid rgba(237, 237, 237, 1);
                &:last-child{
                    border-bottom: none;
                }
                .goods{
                    display: flex;
                    align-items: center;
                    &>img{
                        width: 100px;
                        height: 100px;
                    }
                    .info{
                        margin-left: 10px;
                        flex: 1;
                        .name{
                            font-size: 15px;
                            font-weight: 400;
                            color: rgba(56, 56, 56, 1);
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                        }
                        .specification{
                            margin-top: 7px;
                            font-size: 13px;
                            font-weight: 400;
                            color: rgba(166, 166, 166, 1);
                            &>span{
                                margin-right: 5px;
                            }
                        }
                        .priceNum{
                            display: flex;
                            justify-content: space-between;
                            margin-top: 7px;
                            font-size: 13px;
                            font-weight: 400;
                            color: rgba(56, 56, 56, 1);
                        }
                    }
                }
                .msg{
                    margin-top: 10px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                    display: flex;
                    &>span:nth-child(2){
                        flex: 1;
                        margin-left: 10px;
                        input{
                            border: none;
                            outline: none;
                            background: transparent;
                            font-size:12px;
                        }
                    }
                }
                .freight{
                    margin-top: 10px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                    display: flex;
                    justify-content: space-between;
                    &>span:nth-child(2){
                        color: rgba(166, 166, 166, 1);
                    }
                }
                .goodsPrice{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                    &>span:nth-child(2){
                        color: rgba(255, 141, 26, 1);
                    }
                }
            }
        }
        .payInfo{
            padding: 25px 15px;
            .payPrice{
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: 400;
                color: rgba(56, 56, 56, 1);
                &>span:nth-child(2){
                    font-weight: 700;
                    color: rgba(212, 48, 48, 1);
                }
            }
            .payAccount{
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .qr{
                    width: 76px;
                    height: 76px;
                    /deep/img{
                        width: 76px;
                        height: 76px;
                        object-fit:cover;
                    }
                }
            }
        }
        .tip{
            padding: 15px 15px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 141, 26, 1);
        }
    }

    .tipBox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ti{
            width: 300px;
            font-size: 20px;
            font-weight: 400;
            color: #333;
            display: flex;
            align-items: center;
            i{
                color: rgba(0, 128, 0,0.6);
                font-size: 32px;
                margin-right: 10px;
            }
        }
        .sn{
            margin: 24px 0;
            width: 300px;
            font-size: 16px;
            font-weight: 400;
            color: #333;
        }
        .price{
            width: 300px;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            &>span{
                color: rgba(255, 87, 51, 1)
            }
        }
        .ope{
            margin-top: 34px;
            width: 300px;
            display: flex;
            align-items: center;
            .imm{
                width: 112px;
                height: 34px;
                opacity: 1;
                border-radius: 2px;
                background: rgba(255, 87, 51, 1);
                font-size: 16px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .out{
                width: 112px;
                height: 34px;
                opacity: 1;
                border-radius: 2px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(166, 166, 166, 1);
                margin-left: 27px;

                font-size: 16px;
                font-weight: 400;
                color: rgba(128, 128, 128, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}
</style>